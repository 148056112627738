// import API from "@Modules/Report/configs/api";
import API from "../../configs/api";
import apiMethod from "@utility/ApiMethod";

export const getHomeData = async (codeLanguage = "vi-VN") => {
  try {
    const { data } = await apiMethod.get(`/${codeLanguage}/${API.GET_BANNER}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getUserInviteHistory = (payload) => {
  return apiMethod.post(
    payload?.codeLanguage +
      API.GET_USER_INVITE_HISTORY +
      `?page=${payload?.page}&limit=${payload?.limit}`,
    payload
  );
};

export const createSubmitReport = (payload) => {
  return apiMethod.post(API.CREATE_SUBMIT_REPORT, payload);
};

export const getListAffiliateReport = (payload) => {
  const { limit, page, userId } = payload;
  return apiMethod.post(
    API.GET_MST_AFFILIATE_REPORT_LIST + `?limit=${limit}&page=${page}`,
    {
      userId: userId,
    }
  );
};

export const optionReportProcess = (payload) => {
  const {groupCode} = payload;
  return apiMethod.get(API.OPTION_REPORT_PROCESSING + `/${groupCode}`)
}