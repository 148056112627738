import API from "@Modules/PaymentHistory/configs/api";
import apiMethod from "@utility/ApiMethod";

export const getHomeData = async (codeLanguage = "vi-VN") => {
  try {
    const { data } = await apiMethod.get(`/${codeLanguage}/${API.GET_BANNER}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getPayHistory = (payload) => {
  const { codeLanguage, limit, page, userId, ...res } = payload;
  return apiMethod.post(
    codeLanguage + API.GET_PAY_HISTORY + `?page=${page}&limit=${limit}`,
    {
      userId: userId,
      ...res,
    }
  );
};

export const getPayHistoryDetail = (payload) => {
  const { codeLanguage, limit, page, body } = payload;
  return apiMethod.post(
    codeLanguage + API.GET_DETAIL_PAY_HISTORY + `?page=${page}&limit=${limit}`,
    body
  );
};

export const exportPaymentHistory = async (payload) => {
  try {
    const { codeLanguage, ...rest } = payload;
    const response = await apiMethod.post(
      `${codeLanguage}${API.EXPORT_PAY_HISTORY}`,
      rest,
      {
        responseType: "blob",
      }
    );
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getListReplies = (payload) => {
  const { infoUserCommisionAuditId, limit, page } = payload;
  return apiMethod.post(API.POST_LIST_REPLY + `?page=${page}&limit=${limit}`, {
    infoUserCommisionAuditId: infoUserCommisionAuditId,
  });
};

export const createRepliesHistoryPayment = (payload) => {
  return apiMethod.post(API.CREATE_PLIES, {
    ...payload
  });
}