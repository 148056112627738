import API from "@Modules/Notification/configs/api";
import apiMethod from "@utility/ApiMethodV1STNHD";
import { APP_ID } from "@configs/contants";

export const getNotificationList = async (payload) => {
  try {
    const { appName = "5PTB", page, limit, ...rest } = payload;
    const { data } = await apiMethod.post(`${API.NOTIFICATION_LIST}`, rest, {
      params: {
        appName,
        page,
        limit,
      },
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getDetailNotification = async (payload) => {
  try {
    const { data } = await apiMethod.get(
      `${API.NOTIFICATION_DETAIL}/${payload?.id}?userId=${payload?.userId}&appName=${APP_ID}`,
      {}
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateNotification = async (payload) => {
  try {
    const { id, ...rest } = payload;
    const { data } = await apiMethod.put(
      `${API.NOTIFICATION_UPDATE}`,
      payload
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const countNotification = async (userid) => {
  try {
    const { data } = await apiMethod.get(`${API.NOTIFICATION_COUNT}`, {
      params: { userId: userid, appName: APP_ID },
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const markReadAllNotification = async (payload) => {
  try {
    const { data } = await apiMethod.get(
      `${API.NOTIFICATION_MARK_READ_ALL}`,{params:payload}
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
