import React from "react";

import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import ModalFooter from "reactstrap/es/ModalFooter";

export default class SlideInModal extends React.PureComponent {
  static instance = null;

  static show = (
    onShowCb = () => { },
    header = <div />,
    body = <div />,
    footer = <div />,
    closable = true,
    className = ""
  ) => {
    onShowCb();
    if (SlideInModal.instance) {
      SlideInModal.instance.setState({ visible: false }, () => {
        SlideInModal.instance.setState({
          visible: true,
          header,
          body,
          footer,
          closable,
          className,
        });
      });
    }
  };

  static hide = (onHideCb = () => { }) => {
    if (SlideInModal.instance) {
      SlideInModal.instance.setState({ visible: false });
      const timeout = setTimeout(() => {
        onHideCb();
        clearTimeout(timeout);
      }, 300);
    }
  };
  static getIsShow = () => {
    return SlideInModal.instance.state?.visible || false;
  };

  constructor(props) {
    super(props);
    SlideInModal.instance = this;

    this.state = {
      visible: false,
      header: <div />,
      body: <div />,
      footer: <div />,
      closable: true,
      className: "",
    };
  }

  render() {
    return (
      <Modal
        isOpen={SlideInModal?.instance?.state?.visible || false}
        toggle={() => SlideInModal.hide()}
        className="modal-dialog-centered"
        modalClassName={`modal-danger ${SlideInModal?.instance?.state?.className}`}
        backdrop={SlideInModal?.instance?.state?.closable ? true : "static"}
      >
        {this.state.closable ? (
          <ModalHeader
            toggle={() => SlideInModal.hide()}
            style={{ display: "none" }}
          >
            {this.state.header ? this.state.header : <div />}
          </ModalHeader>
        ) : (
          this.state.header || <div />
        )}

        <ModalBody>{this.state.body ? this.state.body : <div />}</ModalBody>
        <ModalFooter>
          {this.state.footer ? this.state.footer : <div />}
        </ModalFooter>
      </Modal>
    );
  }
}
