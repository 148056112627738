import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
// import initReducer from './Store/initReducer';

export default {
  name: "Profile",
  dir: "Profile",
  pathRoot: "profile",
  routes: [
    {
      url: "verify-account",
      component: "Page/index",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Thông tin cá nhân | 5 Phút Thuộc Bài",
        titleI18n: "CORE:profile_info",
        exact: true,
      },
    },
    {
      url: "search-token",
      component: "Page/SeachTokenPage",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Tra cứu token | 5 Phút Thuộc Bài",
        titleI18n: "Tra cứu token | 5 Phút Thuộc Bài",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  // redux: initReducer,
};
