import API from "@Modules/Home/configs/api";
import apiMethod from "@utility/ApiMethod";

export const getHomeData = async (codeLanguage = "vi-VN") => {
  try {
    const { data } = await apiMethod.get(`/${codeLanguage}/${API.GET_BANNER}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getMstSetting = (payload) => {
  const { codeLanguage, limit, page, code } = payload;
  return apiMethod.post(
    codeLanguage + API.GET_MST_SETTING + `?limit=${limit}&page=${page}`,
    {
      code: code,
    }
  );
};

export const getAffiliateSummary = (payload) => {
  const { codeLanguage, dateFrom, dateTo } = payload;
  // console.log("payload-sum", payload);
  return apiMethod.post(codeLanguage + API.GET_AFFILIATE_SUMMARY, {
    dateFrom: dateFrom,
    dateTo: dateTo,
  });
};

export const getAffiliateStatistical = (payload) => {
  // console.log("payload-chart", payload);
  return apiMethod.post(
    payload?.codeLanguage + API.GET_AFFILIATE_STATISTICAL,
    payload
  );
};

export const getListPayHistory = (payload) => {
  const { limit, page, codeLanguage, body } = payload;
  return apiMethod.post(
    codeLanguage + API.LIST_PAY_HISTORY + `?limit=${limit}&page=${page}`,
    body
  );
};

export const checkCodeInvite = (payload) => {
  const { inviteCode, codeLanguage } = payload;
  return apiMethod.get(
    codeLanguage + API.CHECK_INVITE_CODE + `?inviteCode=${inviteCode}`
  );
};
