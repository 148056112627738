// import API from "@Modules/RegisterAffiliate/configs/api";
import API from "../../configs/api";
import apiMethod from "@utility/ApiMethod";
import {
  MST_OPTION_RULE_PARTNER,
  MST_OPTION_RULE_PULISHER,
} from "@src/store/common/constants";

export const getHomeData = async (codeLanguage = "vi-VN") => {
  try {
    const { data } = await apiMethod.get(`/${codeLanguage}/${API.GET_BANNER}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getUserInviteHistory = (payload) => {
  return apiMethod.post(
    payload?.codeLanguage +
      API.GET_USER_INVITE_HISTORY +
      `?page=${payload?.page}&limit=${payload?.limit}`,
    payload
  );
};

export const getListReplyHistoryIntro = (payload) => {
  const { limit, page, userId, infoUserInviteCommisionHistoryId } = payload;
  return apiMethod.post(
    API.POST_LIST_FILTER_REPLY + `?limit=${limit}&page=${page}`,
    {
      userId: userId,
      infoUserInviteCommisionHistoryId: infoUserInviteCommisionHistoryId,
    }
  );
};

export const createReplyHistoryIntro = (payload) => {
  return apiMethod.post(API.CREATE_REPLY, {
    ...payload,
  });
};

export const exportReferralHistory = async (payload) => {
  try {
    const { page, limit, codeLanguage, ...rest } = payload;
    const response = await apiMethod.post(
      `${codeLanguage}${API.EXPORT_REFERRAL_HISTORY}`,
      rest,
      { params: { page, limit }, responseType: "blob" }
    );
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
export const apiGetRulesPulisher = async () => {
  try {
    const { data } = await apiMethod.get(
      `${API.MST_OPTIONE_DETAIL}${MST_OPTION_RULE_PULISHER}`
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
export const apiGetRulesPartner = async () => {
  try {
    const { data } = await apiMethod.get(
      `${API.MST_OPTIONE_DETAIL}${MST_OPTION_RULE_PARTNER}`
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
export const putSubmitRegister = async (payload) => {
  const { codeLanguage, ...rest } = payload;
  try {
    const { data } = await apiMethod.put(
      `${codeLanguage}${API.SUBMIT_AFFILIATE}`,
      rest
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
