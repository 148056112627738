import {
  ENV_ENVIRONMENT_BASE_URL_API,
  ENV_ENVIRONMENT_BASE_URL_API_DEV,
} from "./environment";
export const BASE_URL_API = ENV_ENVIRONMENT_BASE_URL_API;
export const BASE_URL_API_DEV = ENV_ENVIRONMENT_BASE_URL_API_DEV;

export default {
  /*Basic*/
  Province: "/Province",
  District: "/District",
  SchoolClass: "/SchoolClass",

  /*Common*/
  GET_COMMON_GET_CITY: "/MProvince/list-province",
  GET_COMMON_GET_DISTRICT: "/District",
  GET_LIST_CLASS: "/SchoolClass",
  GET_SHARE_INFO: "WebsiteSTNHD/ShareInfo",
  GET_LIST_OPTIONS_SETUP: "/Options/list/groupcode/otp_setup",
  POST_SEND_OTP_EMAIL: "/AccountHelperEmail/SendOTPEmail",
  GET_ACCOUNT_PROFILE: "/User/GetProfile",
  POST_CHECK_ACTIVE_CLASS_CODE: "/SieuTriNhoHocDuong/CheckCode5PTB",
  POST_ACTIVE_CLASS_CODE: "/SieuTriNhoHocDuong/ActiveCode5PTB",
  CHECK_CODE_INVITE: "/User/check-invite-code",
  /*End Common*/

  //new
  UPDATE_USER_INFO: "/User/update",

  SEND_OTP_V2: "/User/sendotp",
  CHECK_IS_UPDATE_INFO: "/User/check-update-bankInfo",
  SAVE_SETTING_COLUMN: "/AffiliateReportSettings/update-setting",
  GET_SETTING_COLUMN: "/AffiliateReportSettings/get-setting-for-user",
};
