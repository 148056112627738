import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";
import initSagas from "./Store/initSagas";

export default {
  name: "ConfirmOrder",
  dir: "ConfirmOrder",
  pathRoot: "",
  routes: [
    {
      url: "confirm-order",
      component: "Page",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Xác nhận đơn hàng đổi thẻ| 5 Phút Thuộc Bài",
        titleI18n: "ConfirmOrder:title",
        headerStyle: "fill",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  sagas: initSagas,
};
