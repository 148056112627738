import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";

export default {
  name: "Notification",
  dir: "Notification",
  pathRoot: "notification",
  routes: [
    {
      url: "detail/:id",
      component: "Page/DetailNotif",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Thông báo - Tâm Trí Lực",
        titleI18n: "Notification:title",
        exact: false,
      },
    },
    {
      url: "",
      component: "Page/index",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Thông báo - Tâm Trí Lực",
        titleI18n: "Notification:title",
        exact: false,
      },
    },

  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
};
