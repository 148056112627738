export default {
  // detail referral history
  GET_USER_INVITE_HISTORY: "/User/list-user-commision-history",

  // list filter reply
  POST_LIST_FILTER_REPLY: "/MstCommisionTicketReplies/listFilter",

  // create reply
  CREATE_REPLY: "/MstCommisionTicketReplies/Create",

  // export referral history
  EXPORT_REFERRAL_HISTORY: "/Affiliate/export-list-user-commision-history",

  // get List  sales oder
  GET_LIST_SALE_ODER: "/AdminAffiliate/list-salesorder",

  // get List  sales oder affiliate
  GET_LIST_SALE_ODER_AFFILIATE: "/AdminAffiliate/list-salesorder-affiliate",

  // export sales oder
  EXPORT_SALE_ODER: "/AdminAffiliate/export-salesorder",
  EXPORT_SALE_ODER_AFFILIATE: "/AdminAffiliate/export-salesorder-affiliate",
};
