import intlMessagesEN from "./i18n/localization/en.json"
import intlMessagesVi from "./i18n/localization/vi.json"
// import initReducer from './Store/initReducer';

export default {
  name: "Affiliate",
  dir: "Affiliate",
  pathRoot: "",
  routes: [
    // {
    //   url: "affiliate",
    //   component: "Page/index",
    //   layout: "VerticalLayout",
    //   meta: {
    //     authRoute: true,
    //   },
    //   props: {
    //     title: "Quản lý thẻ | 5 Phút Thuộc Bài",
    //     titleI18n: "Quản lý thẻ",
    //     exact: true,
    //   },
    // },
    {
      url: "affiliate",
      component: "Page/Recover",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Quản lý thẻ | 5 Phút Thuộc Bài",
        titleI18n: "Quản lý thẻ",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  // redux: initReducer,
};
