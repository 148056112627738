export default {
  // detail referral history
  GET_USER_INVITE_HISTORY: "/User/list-user-commision-history",

  // report violation
  // GET_MST_AFFILIATE_REPORT_LIST: "/MstAffiliateReport/listFilter",
  GET_MST_AFFILIATE_REPORT_LIST: "/MstAffiliateReport/listClient",
  OPTION_REPORT_PROCESSING: "/MstOptions/list/groupcode",

  // submit report
  CREATE_SUBMIT_REPORT: "/MstAffiliateReport/Create",
};
