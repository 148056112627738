import API from "@Modules/ProblemReport/configs/api";
import apiMethod from "@utility/ApiMethod";
import { APP_ID } from "../../configs/constants";

export const getHomeData = async (codeLanguage = "vi-VN") => {
  try {
    const { data } = await apiMethod.get(`/${codeLanguage}/${API.GET_BANNER}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getTicketType = (payload) => {
  const { ticketType } = payload;
  return apiMethod.get(API.GET_TICKET_TYPE + `/${ticketType}`);
};

export const submitTicket = (payload) => {
  const { body } = payload;
  return apiMethod.post(API.POST_TICKET_CREATE + `?appName=${APP_ID}`, body);
};

export const detailTicket = (payload) => {
  return apiMethod.get(API.POST_TICKET_DETAIL + `/${payload.id}`, payload);
};

export const postGetTicketListFilter = (payload) => {
  const { limit, page, ...res } = payload;

  return apiMethod.post(
    `${API.POST_TICKET_FILTER}?appname=${APP_ID}&page=${page}&limit=${limit}`,
    {
      ...res,
    }
  );
};

export const postGetTicketListFilterReplies = (payload) => {
  const { limit, page, ...res } = payload;

  return apiMethod.post(
    `${API.POST_LIST_TICKET_REPLIES}?appname=${APP_ID}&page=${page}&limit=${limit}`,
    {
      ...res,
    }
  );
};

export const postGetTicketRepliesCreate = (payload) => {
  return apiMethod.post(`${API.POST_LIST_TICKET_REPLIES_CREATE}`, {
    ...payload,
  });
};