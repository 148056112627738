import { APP_ID } from "@src/configs/contants";
import API from "../../configs/api";
import apiMethod from "../../utility/ApiMethod";
import apiMethodDev from "../../utility/ApiMethodDev";

export const getProvince = () => {
  return apiMethod.get(API.Province);
};

export const getListProvince = (payload) => {
  const { culture, page, limit, ...res } = payload;

  return apiMethod.get(`${culture}${API.GET_COMMON_GET_CITY}`, {
    params: {
      page,
      limit,
      ...res,
    },
  });
};

export const getDistrict = ({ ProvinceId }) => {
  return apiMethod.get(API.District, {
    params: {
      ProvinceId,
    },
  });
};

export const sendOtp_V2 = ({ codeLanguage, payload }) => {
  return apiMethod.post(codeLanguage + API.SEND_OTP_V2, payload);
};

export const getSchoolClass = () => {
  return apiMethod.get(API.SchoolClass);
};

export const getListCity = (payload) => {
  return apiMethod.get(API.GET_COMMON_GET_CITY, payload);
};

export const getListDistrict = (payload) => {
  return apiMethod.get(API.GET_COMMON_GET_DISTRICT, payload);
};

export const getShareInfoData = async (codeLanguage = "vi-VN") => {
  try {
    const { data } = await apiMethod.get(
      `/${codeLanguage}/${API.GET_SHARE_INFO}`
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getOptionSetup = (payload) => {
  return apiMethod.get(API.GET_LIST_OPTIONS_SETUP, payload);
};
export const getOptionSetupDev = (payload) => {
  return apiMethodDev.get(API.GET_LIST_OPTIONS_SETUP, payload);
};

export const sendOtpEmail = (payload) => {
  return apiMethod.post(API.POST_SEND_OTP_EMAIL, {
    ...payload,
    appID: APP_ID,
  });
};

export const checkCodeInvite = ({ codeLanguage, payload }) => {
  return apiMethod.get(`${codeLanguage}${API.CHECK_CODE_INVITE}`, {
    params: {
      inviteCode: payload?.inviteCode,
    },
  });
};

export const apiGetIsUpdateBankInfo = async (payload) => {
  const { codeLanguage = "vi-VN", ...rest } = payload;
  try {
    const { data } = await apiMethod.get(
      `${codeLanguage}${API.CHECK_IS_UPDATE_INFO}`,
      { params: { userId: payload?.userId } }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const saveSettingColumn = async (
  payload = { userId: 0, key: "", arrSetting: "" }
) => {
  const { codeLanguage = "vi-VN", userId, key, arrSetting, ...rest } = payload;
  try {
    const { data } = await apiMethod.post(
      `${codeLanguage}${API.SAVE_SETTING_COLUMN}`,
      {
        userId: userId,
        reportName: key,
        columnsExport: arrSetting,
      }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
export const getSettingColumn = async (payload) => {
  const { codeLanguage = "vi-VN", userId, key, ...rest } = payload;
  try {
    const { data } = await apiMethod.get(
      `${"vi-VN"}${API.GET_SETTING_COLUMN}/${userId}`
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
