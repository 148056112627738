import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";

export default {
  name: "Authenticate",
  dir: "Authenticate",
  pathRoot: "",
  routes: [
    {
      url: "",
      component: "Page/LoginBody",
      layout: "BlankLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Đăng nhập | Tâm Trí Lực",
        titleI18n: "FormSignIn:title",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: false,
};
