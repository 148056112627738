// import API from "@Modules/OrderHistory/configs/api";
import API from "../../configs/api";
import apiMethod from "@utility/ApiMethod";

export const getHomeData = async (codeLanguage = "vi-VN") => {
  try {
    const { data } = await apiMethod.get(`/${codeLanguage}/${API.GET_BANNER}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getListOrderToConfirm = (payload) => {
  const { limit, page, userId, infoUserInviteCommisionHistoryId, ...resBody } =
    payload;
  return apiMethod.post(
    API.LIST_ORDER_TO_CONFIRM + `?limit=${limit}&page=${page}`,
    {
      userId: userId,
      ...resBody,
      // infoUserInviteCommisionHistoryId: infoUserInviteCommisionHistoryId,
    }
  );
};
export const confirmOrder = (payload) => {
  const { userId, orderCode } = payload;
  return apiMethod.post(
    API.CONFIRM_ORDER + `?userId=${userId}&orderCode=${orderCode}`
  );
};
