export default [
  { key: "Contract" },
  { key: "Contact" },
  { key: "Authenticate" },
  { key: "Home" },
  { key: "Notification" },
  { key: "Profile" },
  { key: "ReferralHistory" },
  { key: "Report" },
  { key: "PaymentHistory" },
  { key: "Support" },
  { key: "ProblemReport" },
  { key: "Collaborator" },
  { key: "Revenue" },
  { key: "OrderHistory" },
  { key: "RegisterAffiliate" },
  localStorage.getItem("userType") === "1" ? { key: "Affiliate" } : false,
  localStorage.getItem("userType") === "1" ? { key: "ConfirmOrder" } : false,
].filter(Boolean);
