// import API from "@Modules/OrderHistory/configs/api";
import API from "../../configs/api";
import apiMethod from "@utility/ApiMethod";

export const getHomeData = async (codeLanguage = "vi-VN") => {
  try {
    const { data } = await apiMethod.get(`/${codeLanguage}/${API.GET_BANNER}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getUserInviteHistory = (payload) => {
  return apiMethod.post(
    payload?.codeLanguage +
      API.GET_USER_INVITE_HISTORY +
      `?page=${payload?.page}&limit=${payload?.limit}`,
    payload
  );
};

export const getListReplyHistoryIntro = (payload) => {
  const { limit, page, userId, infoUserInviteCommisionHistoryId } = payload;
  return apiMethod.post(
    API.POST_LIST_FILTER_REPLY + `?limit=${limit}&page=${page}`,
    {
      userId: userId,
      infoUserInviteCommisionHistoryId: infoUserInviteCommisionHistoryId,
    }
  );
};

export const createReplyHistoryIntro = (payload) => {
  return apiMethod.post(API.CREATE_REPLY, {
    ...payload,
  });
};

export const exportReferralHistory = async (payload) => {
  try {
    const { page, limit, codeLanguage, ...rest } = payload;
    const response = await apiMethod.post(
      `${codeLanguage}${API.EXPORT_REFERRAL_HISTORY}`,
      rest,
      { params: { page, limit }, responseType: "blob" }
    );
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

/// Api for this modules
export const apiGetListSalesorder = (payload) => {
  return apiMethod.post(
    payload?.codeLanguage +
      API.GET_LIST_SALE_ODER +
      `?page=${payload?.page}&limit=${payload?.limit}`,
    payload
  );
};
export const apiGetListSalesorderAffiliate = (payload) => {
  return apiMethod.post(
    payload?.codeLanguage +
      API.GET_LIST_SALE_ODER_AFFILIATE +
      `?page=${payload?.page}&limit=${payload?.limit}`,
    payload
  );
};
/// Export sale order
export const exportSaleOder = (payload) => {
  return apiMethod.post(
    payload?.codeLanguage + API.EXPORT_SALE_ODER,
    // `?page=${payload?.page}&limit=${payload?.limit}`,
    payload,
    { responseType: "blob" }
  );
};
/// Export sale order
export const exportSaleOderAffiliate = (payload) => {
  const { userId, startDate, endDate, page, limit } = payload;
  return apiMethod.post(
    payload?.codeLanguage + API.EXPORT_SALE_ODER_AFFILIATE,
    // `?page=${payload?.page}&limit=${payload?.limit}`,
    { userId, startDate, endDate },
    { params: { page, limit }, responseType: "blob" }
  );
};
