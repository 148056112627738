export default {
  GET_BANNER: "WebsiteSTNHD/TrangChu",

  //rules program
  GET_MST_SETTING: "/MstSetting/listFilter",

  //affiliate summary user
  GET_AFFILIATE_SUMMARY: "/Affiliate/affiliate-summary-user",

  //affiliate statistical
  GET_AFFILIATE_STATISTICAL: "/Affiliate/chart-affiliate-statistic",
  // GET_AFFILIATE_STATISTICAL: "/Affiliate/affiliate-statistic",
  // GET_AFFILIATE_STATISTICAL: "/AdminAffiliate/admin-user-history",

  // list pay history
  LIST_PAY_HISTORY: "/Affiliate/list-pay-history",

  // check invite code

  CHECK_INVITE_CODE: "/User/check-invite-code",
};
