export default {
  // detail referral history
  GET_USER_INVITE_HISTORY: "/User/list-user-commision-history",

  // list filter reply
  POST_LIST_FILTER_REPLY: "/MstCommisionTicketReplies/listFilter",

  // create reply
  CREATE_REPLY: "/MstCommisionTicketReplies/Create",

  // export referral history
  EXPORT_REFERRAL_HISTORY: "/Affiliate/export-list-user-commision-history",
  // export referral history
  MST_OPTIONE_DETAIL: "/MstOptions/detail/",
  // export referral history
  SUBMIT_AFFILIATE: "/User/update-form-affiliate",
};
