const route_affiliate_ticket = "/MstAffiliateTicket/";
const route_affiliate_ticket_replies = "/MstAffiliateTicketReplies/";

export default {
  // options
  GET_TICKET_TYPE: "/MstOptions/list/groupcode",

  // SUBMIT TICKET
  POST_TICKET_CREATE: `${route_affiliate_ticket}Create`,

  // TICKET DETAIL
  POST_TICKET_DETAIL: `${route_affiliate_ticket}detail`,
  POST_TICKET_FILTER: `${route_affiliate_ticket}listFilter`,
  POST_LIST_TICKET_REPLIES: `${route_affiliate_ticket_replies}listFilter`,
  POST_LIST_TICKET_REPLIES_CREATE: `${route_affiliate_ticket_replies}Create`,
};
