export default {
  // detail referral history
  GET_USER_INVITE_HISTORY: "/User/list-user-commision-history",

  // pay history
  GET_PAY_HISTORY: "/Affiliate/pay-history",
  GET_DETAIL_PAY_HISTORY: "/Affiliate/pay-history-detail",

  // export pay history
  EXPORT_PAY_HISTORY: "/Affiliate/export-pay-history",

  // list reply
  POST_LIST_REPLY: "/MstAuditTicketReplies/listFilter",
  CREATE_PLIES: "/MstAuditTicketReplies/Create",
  LIST_ORDER_TO_CONFIRM: "RecallsCard/get-list-confirm",
  CONFIRM_ORDER: "RecallsCard/recalls-confirming",
  
};
