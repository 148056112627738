import intlMessagesEN from "./i18n/localization/en.json"
import intlMessagesVi from "./i18n/localization/vi.json"
// import initReducer from './Store/initReducer';

export default {
  name: "Contract",
  dir: "Contract",
  pathRoot: "contract",
  routes: [
    {
      url: "",
      component: "Page/index",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Ký hợp đồng | 5 Phút Thuộc Bài",
        titleI18n: "contract:title",
        exact: true,
      },
    },
    {
      url: "verifi",
      component: "Page/Verifi",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Xác thực tài khoản | 5 Phút Thuộc Bài",
        titleI18n: "CORE:profile_info",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  // redux: initReducer,
};
